import axios from 'axios';
import CustomerFeedbackInterface from '@/interfaces/CustomerFeedbackInterface';
import { env } from '@/helpers';

class CustomerFeedbackAPI {
  static get(categories: string[] = ['all']): Promise<Record<string, CustomerFeedbackInterface[]>> {
    return new Promise((resolve, reject) => {
      axios.get<Record<string, CustomerFeedbackInterface[]>>(`${env('API_BASE_PATH')}customer-feedback`, {
        params: {
          categories: categories.join(','),
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e);
      });
    });
  }
}

export default CustomerFeedbackAPI;
