import { Module } from 'vuex';

const exitPopupModule: Module<any, any> = {
  namespaced: true,

  state: {
    url: null,
  },

  mutations: {
    SET_URL(state, url: string) {
      state.url = url;
    },
  },

  actions: {
    setUrl({ commit }, url: string) {
      commit('SET_URL', url);
    },
  },

  getters: {
    url(state): string {
      return state.url;
    },
  },

};

export default exitPopupModule;
