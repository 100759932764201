import DataPreparedInterface from '@/interfaces/order/DataPreparedInterface';
import { Module } from 'vuex';
import { IoOrderAPI } from '@/api';

declare interface IoDate {
  format: (format: string) => string;
}

const dataPreparedModule: Module<any, any> = {
  namespaced: true,

  state: {
    data: null,
  },

  mutations: {
    SET_DATA(state, data: DataPreparedInterface) {
      state.data = data;
    },
  },

  actions: {
    setData({ commit }, data: DataPreparedInterface) {
      commit('SET_DATA', data);
    },
    fetch({ commit }) {
      return new Promise((resolve, reject) => {
        IoOrderAPI.getDataPrepared().then((response) => {
          commit('SET_DATA', response);
          resolve(response);
        }).catch((e) => {
          reject(e);
        });
      });
    },
  },

  getters: {
    data: (state): null | DataPreparedInterface => state.data,
    movingDateIsSelect: (state): boolean => state?.data?.moving?.change_date.dates
      && Array.isArray(state?.data?.moving?.change_date.dates)
      && state?.data?.moving?.change_date.dates.length > 0,
    productChangeDateIsSelect: (state): boolean => state?.data?.['supplier-change']?.change_date.dates
      && Array.isArray(state?.data?.['supplier-change']?.change_date.dates)
      && state?.data?.['supplier-change']?.change_date.dates.length > 0,
    movingDateOptions: (state) => {
      if (
        state?.data?.moving?.change_date.dates
        && Array.isArray(state?.data?.moving?.change_date.dates)
        && state?.data?.moving?.change_date.dates.length > 0
      ) {
        return state?.data?.moving?.change_date.dates.map((dateString: string) => ({
          value: dateString,
          label: (new Date(dateString) as unknown as IoDate).format(state?.data?.wpDateFormat || 'DD.MM.YYY'),
        }));
      }

      return [{
        value: null,
        label: '#',
      }];
    },
    productChangeDateOptions: (state) => {
      if (
        state?.data?.['supplier-change']?.change_date.dates
        && Array.isArray(state?.data?.['supplier-change']?.change_date.dates)
        && state?.data?.['supplier-change']?.change_date.dates.length > 0
      ) {
        return state?.data?.['supplier-change']?.change_date.dates.map((dateString: string) => ({
          value: dateString,
          label: (new Date(dateString) as unknown as IoDate).format(state?.data?.wpDateFormat || 'DD.MM.YYY'),
        }));
      }

      return [{
        value: null,
        label: '#',
      }];
    },
    productChangeDatePickerDates: (state, getters) => {
      if (!getters.productChangeDateIsSelect) {
        return {
          min: state?.data?.['supplier-change']?.change_date.earliest ? new Date(state?.data?.['supplier-change']?.change_date.earliest) : null,
          max: state?.data?.['supplier-change']?.change_date.latest ? new Date(state?.data?.['supplier-change']?.change_date.latest) : null,
        };
      }

      return [{
        min: null,
        max: null,
      }];
    },
    movingDatePickerDates: (state, getters) => {
      if (!getters.movingDateIsSelect) {
        return {
          min: state?.data?.moving?.change_date.earliest ? new Date(state?.data?.moving?.change_date.earliest) : null,
          max: state?.data?.moving?.change_date.latest ? new Date(state?.data?.moving?.change_date.latest) : null,
        };
      }

      return [{
        min: null,
        max: null,
      }];
    },
  },
};

export default dataPreparedModule;
