import { Module } from 'vuex';

const searchModule: Module<any, any> = {
  namespaced: true,

  state: {
    showForm: false,
  },

  mutations: {
    TOGGLE_SEARCH_FROM(state) {
      state.showForm = !state.showForm;
    },
    OPEN_SEARCH_FROM(state) {
      state.showForm = true;
    },
    CLOSE_SEARCH_FROM(state) {
      state.showForm = false;
    },
  },

  actions: {
    toggle({ commit }) {
      commit('TOGGLE_SEARCH_FROM');
    },
    open({ commit }) {
      commit('OPEN_SEARCH_FROM');
    },
    close({ commit }) {
      commit('CLOSE_SEARCH_FROM');
    },
  },

  getters: {
    showForm(state) {
      return state.showForm;
    },
  },
};

export default searchModule;
