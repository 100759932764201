import axios from 'axios';
import { Product } from '@/classes';
import { getCookie } from '@/helpers';
import { mapCartResponse } from '@/classes/Product';
import CartInterface from '@/interfaces/CartInterface';

class CartAPI {
  static get(): Promise<CartInterface> {
    return new Promise((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.get(`${ajaxUrl}?action=cart`, {
        params: {
          endpoint: 'get',
          cartKey: getCookie('io_shop_cart'),
        },
      }).then((response) => {
        resolve(mapCartResponse(response) as CartInterface);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static add(product: Product, params: Record<string, any>, cartItemKey: string | null): Promise<{
    cartData: CartInterface,
    cartEnabled: boolean,
    cartKey: string,
  }> {
    return new Promise((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.post(`${ajaxUrl}?action=cart`, {
        endpoint: 'add',
        cartKey: getCookie('io_shop_cart'),
        product: product.attributes,
        params,
        cartItemKey,
      }).then((response) => {
        resolve({
          cartData: mapCartResponse(response),
          cartEnabled: (response?.data?.data?.cartEnabled || false) as boolean,
          cartKey: (response?.data?.data?.cartKey || '') as string,
        });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static remove(itemKey: string, product: Product): Promise<{
    cartData: CartInterface,
    cartEnabled: boolean,
    cartKey: string,
  }> {
    return new Promise((resolve, reject) => {
      let { ajaxUrl } = window.ioappConfig;
      const { protocol } = window.location;

      if (protocol !== 'https:') {
        ajaxUrl = ajaxUrl.replace('https://', 'http://');
      }

      axios.post(`${ajaxUrl}?action=cart`, {
        endpoint: 'remove',
        cartKey: getCookie('io_shop_cart'),
        itemKey,
        type: product.productType,
      }).then((response) => {
        resolve({
          cartData: mapCartResponse(response),
          cartEnabled: (response?.data?.data?.cartEnabled || false) as boolean,
          cartKey: (response?.data?.data?.cartKey || '') as string,
        });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static hasCartCookie(): boolean {
    return `${getCookie('io_shop_cart')}`.trim() !== '';
  }
}

export default CartAPI;
