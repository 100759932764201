import { Module } from 'vuex';
import MenuInterface from '@/interfaces/MenuInterface';
import { MenuAPI } from '@/api';

const menusModule: Module<any, any> = {
  namespaced: true,

  state: {
    status: {},
    menus: {},
    errors: {},
  },

  mutations: {
    SET_STATUS(state, data: { slug: string, status: number }) {
      state.status[data.slug] = data.status;
    },
    SET_MENU(state, data: { slug: string, menu: MenuInterface }) {
      state.menus[data.slug] = data.menu;
    },
    SET_ERROR(state, data: { slug: string, error: unknown }) {
      state.errors[data.slug] = data.error;
    },
  },

  actions: {
    fetchBySlug({ commit }, slug: number | string) {
      commit('SET_STATUS', {
        slug,
        status: 0,
      });
      MenuAPI.get(slug)
        .then((response: MenuInterface) => {
          commit('SET_ERROR', {
            slug,
            error: null,
          });
          commit('SET_MENU', {
            slug,
            menu: response,
          });
        }).catch((e) => {
          commit('SET_ERROR', {
            slug,
            error: e,
          });
          commit('SET_MENU', {
            slug,
            menu: null,
          });
        }).finally(() => {
          commit('SET_STATUS', {
            slug,
            status: 0,
          });
        });
    },
  },

  getters: {
    status: (state) => (slug: string) => {
      const status = state.status[slug];

      return typeof status === 'undefined' ? 0 : status;
    },
    menu: (state) => (slug: string) => {
      const menu = state.menus[slug];

      return typeof menu === 'undefined' ? null : menu;
    },
  },
};

export default menusModule;
