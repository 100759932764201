import { Product } from '@/classes';
import { Module } from 'vuex';
import { IoProductAPI } from '@/api';

const priceshowModule: Module<any, any> = {
  namespaced: true,

  state: {
    products: [] as Product[],
    loading: false,
    error: null,
    filter: {
      priceGuarantee: 'all' as any,
      contractTerm: 'all' as any,
    },
  },

  mutations: {
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
    SET_PRODUCTS(state, products: Product[]) {
      state.products = products;
    },
    SET_FILTER(state, filter: any[]) {
      state.filter = filter;
    },
    SET_ERROR(state, error: unknown) {
      state.error = error;
    },
  },

  actions: {
    fetch({ commit }, params) {
      commit('SET_LOADING', true);
      IoProductAPI.getAllWithPrices(params)
        .then((data) => {
          commit('SET_ERROR', null);
          commit('SET_PRODUCTS', data || []);
          commit('SET_FILTER', {
            priceGuarantee: 'all',
            contractTerm: 'all',
          });
        }).catch((e: unknown) => {
          commit('SET_ERROR', e);
          commit('SET_PRODUCTS', []);
          commit('SET_FILTER', {
            priceGuarantee: 'all',
            contractTerm: 'all',
          });
        }).finally(() => {
          commit('SET_LOADING', false);
        });
    },
  },

  getters: {
    products: (state): Product[] => state.products || [],
    productsFiltered: (state): Product[] => (state.products || []).filter((product: Product) => {
      let isMatchingPriceGuarantee = true;
      let isMatchingContractTerm = true;

      if (state.filter.contractTerm !== 'all') {
        const productContractTerm = product.contractTermIsFixed
          ? product.attributes.details.contractDetails.contractTermFixed
          : product.attributes.details.contractDetails.contractTermMonths;

        isMatchingContractTerm = state.filter.contractTerm === productContractTerm;
      }

      if (state.filter.priceGuarantee !== 'all') {
        const productPriceGuarantee = product.priceGuaranteeIsFixed
          ? product.attributes.details.contractDetails.priceGuaranteeTermFixed
          : product.attributes.details.contractDetails.priceGuaranteeTermMonths;

        isMatchingPriceGuarantee = state.filter.priceGuarantee === productPriceGuarantee;
      }

      return isMatchingContractTerm && isMatchingPriceGuarantee;
    }),
    filter: (state): any[] => state.filter || [],
    filterOptions: (state) => {
      const filterOptions = {
        priceGuarantee: [] as { label: string, value?: string }[],
        contractTerm: [] as { label: string, value?: string }[],
      };

      state.products.forEach((product: Product) => {
        const priceGuaranteeOption = {
          label: product.priceGuaranteeLabel,
          value: product.priceGuaranteeIsFixed ? product.attributes.details.contractDetails.priceGuaranteeTermFixed : product.attributes.details.contractDetails.priceGuaranteeTermMonths,
        };

        if (!filterOptions.priceGuarantee.find((opg) => opg.value === priceGuaranteeOption.value)) {
          filterOptions.priceGuarantee.push(priceGuaranteeOption);
        }

        const contractTermOption = {
          label: product.contractTermLabel,
          value: product.contractTermIsFixed ? product.attributes.details.contractDetails.contractTermFixed : product.attributes.details.contractDetails.contractTermMonths,
        };

        if (!filterOptions.contractTerm.find((opg) => opg.value === contractTermOption.value)) {
          filterOptions.contractTerm.push(contractTermOption);
        }
      });
      if (filterOptions.contractTerm.length >= 1) {
        filterOptions.contractTerm = [
          {
            label: 'Alle anzeigen',
            value: 'all',
          },
          ...filterOptions.contractTerm,
        ].sort((a, b) => {
          if (a.value === 'all') return -1;
          if (b.value === 'all') return 1;
          return a.label.localeCompare(b.label);
        });
      } else {
        filterOptions.contractTerm = [];
      }

      if (filterOptions.priceGuarantee.length >= 1) {
        filterOptions.priceGuarantee = [
          {
            label: 'Alle anzeigen',
            value: 'all',
          },
          ...filterOptions.priceGuarantee,
        ].sort((a, b) => {
          if (a.value === 'all') return -1;
          if (b.value === 'all') return 1;
          return a.label.localeCompare(b.label);
        });
      } else {
        filterOptions.priceGuarantee = [];
      }

      return filterOptions;
    },
    hasFilterOptions: (state, getters) => Object.values(getters.filterOptions).filter((filterOptions: any) => filterOptions !== undefined).length > 0,
    loading: (state): boolean => state.loading,
  },
};

export default priceshowModule;
