import { Module } from 'vuex';
import SupplierInterface from '@/interfaces/SupplierInterface';
import SalutationInterface from '@/interfaces/SalutationInterface';
import { OrderAPI } from '@/api';
import BillingTypeInterface from '@/interfaces/BillingTypeInterface';
import dataPreparedModule from '@/store/order/dataPrepared';

const completedSteps = JSON.parse(localStorage.getItem('order-steps') ?? JSON.stringify({
  address: false,
  connection: false,
  payment: false,
}));

const orderModule: Module<any, any> = {
  namespaced: true,

  modules: {
    dataPrepared: dataPreparedModule,
  },

  state: {
    status: 'fetching',
    form: {
      address: {},
      connection: {},
      payment: {},
    },
    completedSteps,
    suppliers: {
      power: [] as SupplierInterface[],
      gas: [] as SupplierInterface[],
    },
    salutations: {
      private: [] as SalutationInterface[],
      business: [] as SalutationInterface[],
    },
    billingTypes: [] as BillingTypeInterface[],
    lastOrderedItem: null as null | Record<string, unknown>,
  },

  mutations: {
    SET_SUPPLIERS(state, suppliers: { power: SupplierInterface[], gas: SupplierInterface[] }) {
      state.suppliers = suppliers;
    },
    SET_SALUTATIONS(state, salutations: SalutationInterface[]) {
      state.salutations = salutations;
    },
    SET_BILLING_TYPES(state, billingTypes: BillingTypeInterface[]) {
      state.billingTypes = billingTypes;
    },
    STORE_STEP(state, { step, data }) {
      if (state.form[step]) {
        state.form[step] = data;
      }
    },
    SET_FORM_DATA(state, data) {
      state.form = {
        ...state.form,
        ...data,
      };
    },
    SET_STATUS(state, status: string) {
      state.status = status;
    },
    SET_LAST_ORDERED_ITEM(state, item: null | Record<string, unknown>) {
      state.lastOrderedItem = item;
    },
    SET_STEP_COMPLETED(state, step) {
      const key = typeof step === 'object' ? step.key : step;

      state.completedSteps[key] = typeof step === 'object' ? step.value : true;

      localStorage.setItem('order-steps', JSON.stringify(state.completedSteps));
    },
  },

  actions: {
    setSuppliers({ commit }, suppliers: { power: SupplierInterface[], gas: SupplierInterface[] }) {
      commit('SET_SUPPLIERS', suppliers);
    },
    setSalutations({ commit }, salutations: SalutationInterface[]) {
      commit('SET_SALUTATIONS', salutations);
    },
    setBillingTypes({ commit }, billingTypes: BillingTypeInterface[]) {
      commit('SET_BILLING_TYPES', billingTypes);
    },
    storeAndSave({ commit }, { step, data }) {
      return new Promise((resolve, reject) => {
        OrderAPI.validate(step, data).then((response) => {
          if (Object.keys(response.errors).length) {
            commit('STORE_STEP', { step, data: {} });
          } else {
            commit('STORE_STEP', { step, data });
          }
          resolve(response);
        }).catch((e) => {
          commit('STORE_STEP', { step, data: {} });
          reject(e);
        });
      });
    },
    submit(state, { data }) {
      return new Promise((resolve, reject) => {
        OrderAPI.submit(data).then((response) => {
          resolve(response);
        }).catch((e) => {
          reject(e);
        });
      });
    },
    setFormData({ commit }, data) {
      commit('SET_FORM_DATA', data);
    },
    setStatus({ commit }, status) {
      commit('SET_STATUS', status);
    },
    setLastOrderedItem({ commit }, item) {
      commit('SET_LAST_ORDERED_ITEM', item);
    },
    deleteLastOrderedItem({ commit }) {
      OrderAPI.deleteLastOrderedItem();
      commit('SET_LAST_ORDERED_ITEM', null);
    },
    clearSteps({ commit }) {
      commit('SET_STEP_COMPLETED', { key: 'address', value: false });
      commit('SET_STEP_COMPLETED', { key: 'connection', value: false });
      commit('SET_STEP_COMPLETED', { key: 'payment', value: false });
    },
  },

  getters: {
    formData: (state) => state.form,
    suppliers: (state) => (branch: string): SupplierInterface[] => state.suppliers[branch === 'electricity' ? 'power' : branch],
    hasSupplier: (state) => (branch: string) => state.suppliers[branch].length > 0,
    salutations: (state) => (customerType: string): SalutationInterface[] => state.salutations[customerType],
    hasSalutations: (state) => (customerType: string): boolean => state.salutations[customerType].length > 0,
    billingTypes: (state) => state.billingTypes,
    hasBillingTypes: (state) => state.billingTypes.length > 0,
    lastOrderedItem: (state) => state.lastOrderedItem,
    completedSteps: (state) => state.completedSteps,
    getSalutationById: (state) => (salutationId: string): string | null => {
      let salutation = null;

      Object.keys(state.salutations).forEach((customerType) => {
        const csal = state.salutations[customerType].find((sal: { value: string, label: string }) => sal.value === salutationId);

        if (csal) {
          salutation = csal.label;
        }
      });

      return salutation;
    },
  },
};

export default orderModule;
