import { Module } from 'vuex';

const enlargementModule: Module<any, any> = {
  namespaced: true,

  state: {
    show: false,
    props: null as Record<string, unknown> | null,
  },

  mutations: {
    TOGGLE_SHOW(state) {
      state.show = !state.show;
    },
    SHOW(state) {
      state.show = true;
    },
    HIDE(state) {
      state.show = false;
    },
    SET_PROPS(state, props: Record<string, unknown>) {
      state.props = props;
    },
    CLEAR(state) {
      state.show = false;
      state.props = null;
    },
  },

  actions: {
    toggle({ commit }) {
      commit('TOGGLE_SHOW');
    },
    show({ commit }) {
      commit('SHOW');
    },
    hide({ commit }) {
      commit('HIDE');
    },
    setProps({ commit }, props: Record<string, unknown>) {
      commit('SET_PROPS', props);
    },
    clear({ commit }) {
      commit('CLEAR');
    },
  },

  getters: {
    show: (state) => state.show && !!state.props,
    props: (state) => ({
      ...(state.props || {}),
      ...{
        align: 'center',
        alignMobile: 'center',
        enlargeable: false,
        isEnlargement: true,
      },
    }),
  },
};

export default enlargementModule;
