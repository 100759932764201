import CartCommodityInterface from '@/interfaces/CartCommodityInterface';
import { objectToUtf8QueryString } from '@/helpers';
import type Product from './Product';

class CartCommodityItem {
  public key: string;

  attributes: CartCommodityInterface;

  constructor(key: string, attributes: CartCommodityInterface) {
    this.key = key;
    this.attributes = attributes;
  }

  get product(): Product {
    return this.attributes.product;
  }

  get params(): Record<string, any> {
    return this.attributes.params as unknown as Record<string, any>;
  }

  get priceshowURL(): string {
    const priceshowURL = `/preisanzeige/${this.params.branch || ''}/${this.params.postcode}/${this.params.usage}`;

    const queryParams = {} as Record<string, any>;

    if (this.params.city) {
      queryParams.stadt = this.params.city;
    }

    if (this.params.street) {
      queryParams.strasse = this.params.street;
    }

    if (this.params.houseNumber) {
      queryParams.hausnummer = this.params.houseNumber;
    }

    if (this.params.business) {
      queryParams.gewerbe = this.params.business;
    }

    queryParams.cik = this.key;

    return `${priceshowURL}${objectToUtf8QueryString(queryParams)}`;
  }
}

export default CartCommodityItem;
