import CartCommodityItem from '@/classes/CartCommodityItem';
import CartInterface from '@/interfaces/CartInterface';
import ProductCategoryInterface from '@/interfaces/ProductCategoryInterface';
import ProductInterface from '@/interfaces/ProductInterface';
import ProductPriceInterface from '@/interfaces/ProductPriceInterface';
import ProductPriceMappedData from '@/interfaces/ProductPriceMappedData';
import BonusInterface from '@/interfaces/BonusInterface';
import { formatNumber } from '@/helpers';
import ProductImage from '@/interfaces/ProductImageInterface';
import ProductDocumentInterface from '@/interfaces/ProductDocumentInterface';
import { AxiosResponse } from 'axios';

class Product {
  attributes: ProductInterface;

  priceKeys: string[];

  priceFormats: Record<'multiply' | 'ceil', Record<string, number>>;

  constructor(attributes: ProductInterface) {
    this.attributes = attributes;

    this.priceKeys = [
      'workingPrice',
      'workingPriceHt',
      'workingPriceNt',
      'basePrice',
      'yearly',
      'monhtly',
      'savingsYearly',
      'savingsMonthly',
    ];

    this.priceFormats = {
      multiply: {
        workingPrice: 100,
        workingPriceHt: 100,
        workingPriceNt: 100,
      },
      ceil: {
        monthly: 1,
        monthlyWithBonus: 1,
      },
    };
  }

  get productType(): string {
    return this.attributes.category.isCommodity ? 'commodity' : 'catalog';
  }

  get price(): ProductPriceInterface | undefined {
    return this.attributes.price;
  }

  set price(price: ProductPriceInterface | undefined) {
    this.attributes.price = price;
  }

  get isEco(): boolean {
    return this.attributes.details.contractDetails.isEco || false;
  }

  get priceCustomerType(): string {
    return this.price?.params?.customerType || 'private';
  }

  get priceIsDouble(): boolean {
    return !!this.attributes?.price?.params?.double;
  }

  get appliedBonuses(): BonusInterface[] {
    return this.attributes?.price?.mapped?.active?.appliedBonus || [];
  }

  get name(): string {
    let formattedProductName = this.attributes.name;

    formattedProductName = formattedProductName
      .replace('hoch3', 'hoch<span class="color-primary">3</span>')
      .replace('HOCH3', 'HOCH<span class="color-primary">3</span>');

    return formattedProductName;
  }

  get id(): number {
    return this.attributes.id;
  }

  get benefits(): string[] {
    return this.attributes.benefits || [];
  }

  get branchTranslated(): string {
    return this.attributes.category.name;
  }

  get isSpotMarketPrices(): boolean {
    return this.attributes.isSpotMarketPrices || false;
  }

  get isRecommended(): boolean {
    return this.attributes.isRecommended || false;
  }

  get category(): ProductCategoryInterface {
    return this.attributes.category;
  }

  get categoryHasIcon(): boolean {
    return !!this.category.icon || !!this.category.image;
  }

  get categoryIconType(): string | undefined {
    if (this.category.icon) {
      return 'icon';
    }
    if (this.category.image) {
      return 'image';
    }

    return undefined;
  }

  get branch(): string {
    return this.category.key;
  }

  public getRecommendationText(isSingle: false): string | null {
    if (this.isRecommended) {
      return isSingle ? (
        this.attributes.recommendationTextSingle
        || this.attributes.recommendationText
        || null
      ) : (
        this.attributes.recommendationText
        || null
      );
    }

    return null;
  }

  get priceFileUrl(): string | null {
    return this.price?.pricefile || null;
  }

  public getPriceshowURL(cid: string | null = null): string | null {
    const customerType = this.attributes.price?.params.customerType || 'private';

    let priceshowURL = `/preisanzeige/${customerType === 'private' ? 'privat' : 'gewerbe'}`;

    if (cid) {
      priceshowURL = `${priceshowURL}&cid=${cid}`;
    }

    return priceshowURL;
  }

  get logoUrl(): string | null {
    let sourceUrl = this.attributes.thumbnail;
    const images = Object.values(this.attributes.details.media.images || []);

    if (sourceUrl === null && this.attributes.details.media) {
      let logoSource = images.find((m: ProductImage) => m.key === 'logo');

      if (logoSource) {
        sourceUrl = `${logoSource.url || ''}`.trim();
      }

      if (sourceUrl === null || sourceUrl.length === 0) {
        logoSource = images.find((m: ProductImage) => m.isPreview);

        if (logoSource) {
          sourceUrl = `${logoSource.url || ''}`.trim();
        }
      }
    }

    return sourceUrl || null;
  }

  public getDocumentByKey(key: string): ProductDocumentInterface | null {
    return Object.values(this.attributes.details.media?.documents || []).find((m: ProductDocumentInterface) => m.key === key) || null;
  }

  public getDocumentUrlByKey(key: string): string {
    return this.getDocumentByKey(key)?.url || '#';
  }

  public hasDocument(key: string): boolean {
    return !!this.getDocumentByKey(key)?.url;
  }

  get priceUsageFormatted(): string {
    return formatNumber(this.price?.params.usage || 0, 0);
  }

  get hasPriceGuarantee(): boolean {
    return this.attributes.details.contractDetails.hasPriceGuarantee || false;
  }

  get contractTermIsFixed(): boolean {
    return this.attributes.details.contractDetails.contractTermMonths === null && !!this.attributes.details.contractDetails.contractTermFixed;
  }

  get priceGuaranteeTermMoths(): `${number}` | undefined {
    return this.attributes.details.contractDetails.priceGuaranteeTermMonths;
  }

  get contractTermMonths(): `${number}` | undefined {
    return this.attributes.details.contractDetails.contractTermMonths;
  }

  get contractTermLabel(): string {
    if (
      this.contractTermIsFixed
      && this.attributes.details.contractDetails.priceGuaranteeTermFixed
    ) {
      const contractTermDateString = this.attributes.details.contractDetails.contractTermFixed || '';
      const contractTermDate = new Date(contractTermDateString);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${`${contractTermDate}` !== 'Invalid Date' ? contractTermDate.format('d.m.Y') : contractTermDateString}`;
    }

    return `${this.attributes.details.contractDetails.contractTermMonths} Monat${(this.attributes.details.contractDetails.contractTermMonths || 0) > 1 ? 'e' : ''}`;
  }

  get priceGuaranteeIsFixed(): boolean {
    return this.attributes.details.contractDetails.priceGuaranteeTermMonths === null && !!this.attributes.details.contractDetails.priceGuaranteeTermFixed;
  }

  get priceGuaranteeLabel(): string {
    if (
      this.priceGuaranteeIsFixed
      && this.attributes.details.contractDetails.priceGuaranteeTermFixed
    ) {
      const priceGuaranteeDateString = this.attributes.details.contractDetails.priceGuaranteeTermFixed;
      const priceGuaranteeDate = new Date(priceGuaranteeDateString);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${`${priceGuaranteeDate}` !== 'Invalid Date' ? priceGuaranteeDate.format('d.m.Y') : priceGuaranteeDateString}`;
    }

    if (this.attributes.details.contractDetails.priceGuaranteeTermMonths) {
      const priceGuaranteeTermMonths = parseInt(this.attributes.details.contractDetails.priceGuaranteeTermMonths || '0', 10);

      return `${priceGuaranteeTermMonths} Monat${priceGuaranteeTermMonths > 1 ? 'e' : ''}`;
    }

    return 'Keine';
  }

  public applyPriceFormats(key: string, value: number, withBonus = false): number {
    let formatKey = key;

    if (withBonus) {
      formatKey = `${formatKey}WithBonus`;
    }

    const valueFormatted = value * (this.priceFormats.multiply[formatKey] || this.priceFormats.multiply[key] || 1);
    const ceilingValue = this.priceFormats.ceil[formatKey] || valueFormatted;

    return Math.ceil(valueFormatted / ceilingValue) * ceilingValue;
  }

  public getPriceFormatted(key: string, digits = 2, priceType: 'gross' | 'net' | null = 'gross'): string {
    return formatNumber(this.applyPriceFormats(key, this.getPriceValue(key, priceType), true), digits);
  }

  public getAbsPriceFormatted(key: string, digits = 2, priceType: 'gross' | 'net' | null = 'gross'): string {
    return formatNumber(this.applyPriceFormats(key, Math.abs(this.getPriceValue(key, priceType)), true), digits);
  }

  public getPriceWithoutBonusFormatted(key: string, digits = 2, priceType: 'gross' | 'net' | null = 'gross'): string {
    return formatNumber(this.applyPriceFormats(key, this.getPriceValueWithoutBonus(key, priceType), false), digits);
  }

  public getAbsPriceWithoutBonusFormatted(key: string, digits = 2, priceType: 'gross' | 'net' | null = 'gross'): string {
    return formatNumber(this.applyPriceFormats(key, Math.abs(this.getPriceValueWithoutBonus(key, priceType)), false), digits);
  }

  public getPriceValue(key: string, priceType: 'gross' | 'net' | null = 'gross'): number {
    let value = 0;

    if (this.priceCustomerType === 'private' || priceType === 'gross') {
      const valGross = this.price?.mapped.active[`${key}WithBonusGross` as keyof ProductPriceMappedData]
        || this.getPriceValueWithoutBonus(key);
      if (typeof valGross === 'number') {
        value = valGross;
      }
    } else if (this.priceCustomerType === 'business' || priceType === 'net') {
      const valNet = this.price?.mapped.active[`${key}WithBonus` as keyof ProductPriceMappedData]
        || this.getPriceValueWithoutBonus(key);

      if (typeof valNet === 'number') {
        value = valNet;
      }
    }

    return value;
  }

  public getPriceValueWithoutBonus(key: string, priceType: 'gross' | 'net' | null = 'gross'): number {
    let value = 0;

    if (this.priceCustomerType === 'private' || priceType === 'gross') {
      const valGross = this.price?.mapped.active[`${key}Gross` as keyof ProductPriceMappedData] || 0;
      if (typeof valGross === 'number') {
        value = valGross;
      }
    } else if (this.priceCustomerType === 'business' || priceType === 'net') {
      const valNet = this.price?.mapped.active[`${key}` as keyof ProductPriceMappedData] || 0;

      if (typeof valNet === 'number') {
        value = valNet;
      }
    }

    return value;
  }
}

export function mapCartResponse(response: AxiosResponse<any>): CartInterface {
  const cartResponseData = response?.data?.data?.cartData;
  const cartData = {
    commodity: [],
    nonCommodity: {},
    campaigns: [],
  } as unknown as CartInterface;

  Object.keys(cartResponseData.commodity).forEach((cartItemKey) => {
    const cartItemData = cartResponseData.commodity[cartItemKey];
    cartItemData.product = new Product(cartItemData.product as ProductInterface);

    cartData.commodity.push(new CartCommodityItem(cartItemKey, cartItemData));
  });

  return cartData;
}

export default Product;
