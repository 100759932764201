import { Module } from 'vuex';
import PowerLabelingInterface from '@/interfaces/PowerLabelingInterface';
import { PowerLabelingAPI } from '@/api';

const powerLabelingsModule: Module<any, any> = {
  namespaced: true,

  state: {
    powerLabelings: [] as PowerLabelingInterface[],
    fetching: 0,
    error: null,
  },

  mutations: {
    SET_POWER_LABELINGS(state, powerLabelings: PowerLabelingInterface[]) {
      state.powerLabelings = powerLabelings;
    },
    START_FETCHING(state) {
      state.fetching = 1;
    },
    STOP_FETCHING(state) {
      state.fetching = 0;
    },
    SET_ERROR(state, error: unknown) {
      state.error = error;
    },
  },

  actions: {
    get({ commit }) {
      commit('START_FETCHING');
      PowerLabelingAPI.get()
        .then((responses: PowerLabelingInterface[]) => {
          commit('SET_ERROR', null);
          commit('SET_POWER_LABELINGS', responses);
        }).catch((e) => {
          commit('SET_ERROR', e);
          commit('SET_POWER_LABELINGS', []);
        }).finally(() => {
          commit('STOP_FETCHING');
        });
    },
  },

  getters: {
    hasCategory: (state) => (category: string) => !!state.contactOptions[category],
    isFetching: (state) => (category: string) => state.fetching.includes(category),
    get(state): PowerLabelingInterface[] {
      return state.powerLabelings;
    },
  },
};

export default powerLabelingsModule;
