import { Module } from 'vuex';
import PageInterface from '@/interfaces/PageInterface';
import { PageAPI } from '@/api';

const pageModule: Module<any, any> = {
  namespaced: true,

  state: {
    status: 1,
    page: null as PageInterface | null,
    type: 'common',
    error: null,
  },

  mutations: {
    SET_STATUS(state, status: number) {
      state.status = status;
    },
    SET_PAGE(state, page: PageInterface) {
      state.page = page;
    },
    SET_PAGE_TYPE(state, pageType: string) {
      state.pageType = pageType;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
  },

  actions: {
    setInitialPageData({ commit }, data: { page: PageInterface, preview: boolean }) {
      commit('SET_PAGE', data.page);
      commit('SET_STATUS', 0);
    },
    fetchFrontPage({ commit }, preview = false) {
      commit('SET_STATUS', 1);
      PageAPI.getFront(preview)
        .then((response: PageInterface) => {
          commit('SET_ERROR', null);
          commit('SET_PAGE', response);
        }).catch((e) => {
          commit('SET_ERROR', e);
          commit('SET_PAGE', null);
        }).finally(() => {
          commit('SET_STATUS', 0);
        });
    },
    fetchCity({ commit }, data: { slug: string, preview: boolean }) {
      commit('SET_STATUS', 1);
      PageAPI.getCity(data.slug, data.preview)
        .then((response: PageInterface | null) => {
          commit('SET_ERROR', null);
          commit('SET_PAGE', response);
        }).catch((e) => {
          commit('SET_ERROR', e);
          commit('SET_PAGE', null);
        }).finally(() => {
          commit('SET_STATUS', 0);
        });
    },
    fetchBySlug({ commit }, data: { slug: string, preview: boolean }) {
      commit('SET_STATUS', 1);
      PageAPI.get(data.slug, data.preview)
        .then((response: PageInterface | null) => {
          commit('SET_ERROR', null);
          commit('SET_PAGE', response);
        }).catch((e) => {
          commit('SET_ERROR', e);
          commit('SET_PAGE', null);
        }).finally(() => {
          commit('SET_STATUS', 0);
        });
    },
    fetchById({ commit }, data: { id: number, preview: boolean }) {
      commit('SET_STATUS', 1);
      PageAPI.getById(data.id, data.preview)
        .then((response: PageInterface | null) => {
          commit('SET_ERROR', null);
          commit('SET_PAGE', response);
        }).catch((e) => {
          commit('SET_ERROR', e);
          commit('SET_PAGE', null);
        }).finally(() => {
          commit('SET_STATUS', 0);
        });
    },
    fetch404({ commit }) {
      commit('SET_STATUS', 1);
      PageAPI.getSystemPage('error-404')
        .then((response: PageInterface | null) => {
          commit('SET_ERROR', null);
          commit('SET_PAGE', response);
        }).catch((e) => {
          commit('SET_ERROR', e);
          commit('SET_PAGE', null);
        }).finally(() => {
          commit('SET_STATUS', 0);
        });
    },
    setPageType({ commit }, pageType: string) {
      commit('SET_PAGE_TYPE', pageType);
    },
    setStatus({ commit }, status: number) {
      commit('SET_STATUS', status);
    },
  },

  getters: {
    status(state): number {
      return state.status;
    },
    page(state): PageInterface {
      return state.page;
    },
    pageType(state): string {
      return state.pageType;
    },
    isOrderPage(state): boolean {
      return state.pageType === 'order';
    },
    hasOrder(state): boolean {
      return !!state.page?.hasOrder;
    },
  },
};

export default pageModule;
