import { createStore } from 'vuex';
import orderModule from '@/store/order';
import pageModule from '@/store/page';
import searchModule from '@/store/search';
import menusModule from '@/store/menus';
import sidebarModule from '@/store/sidebar';
import faqsModule from '@/store/faqs';
import contactOptionsModule from '@/store/contactOptions';
import powerLabelingsModule from '@/store/powerLabelings';
import cartModule from '@/store/cart';
import enlargementModule from '@/store/enlargement';
import customerFeedbacksModule from '@/store/customerFeedback';
import categoriesModule from '@/store/categories';
import branchesModule from '@/store/branches';
import priceshowModule from '@/store/priceshow';
import exitPopupModule from '@/store/exitPopup';

export default createStore({
  modules: {
    page: pageModule,
    search: searchModule,
    menus: menusModule,
    sidebar: sidebarModule,
    faqs: faqsModule,
    contactOptions: contactOptionsModule,
    powerLabelings: powerLabelingsModule,
    cart: cartModule,
    order: orderModule,
    enlargement: enlargementModule,
    customerFeedbacks: customerFeedbacksModule,
    categories: categoriesModule,
    branches: branchesModule,
    priceshow: priceshowModule,
    exitPopup: exitPopupModule,
  },
});
