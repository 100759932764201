import { Module } from 'vuex';
import ContactOptionInterface from '@/interfaces/ContactOptionInterface';
import { ContactOptionAPI } from '@/api';

const contactOptionsModule: Module<any, any> = {
  namespaced: true,

  state: {
    contactOptions: {} as Record<string, ContactOptionInterface[]>,
    fetching: [],
    error: null,
  },

  mutations: {
    SET_CONTACT_OPTIONS(state, contactOptions: Record<string, ContactOptionInterface[]>) {
      state.contactOptions = {
        ...state.contactOptions,
        ...contactOptions,
      };
    },
    START_FETCHING(state, categories: string[]) {
      state.fetching = [...state.fetching, ...categories];
    },
    STOP_FETCHING(state, categories: string[]) {
      state.fetching = [...state.fetching].filter((c) => !categories.includes(c));
    },
    SET_ERROR(state, error: unknown) {
      state.error = error;
    },
  },

  actions: {
    get({ getters, commit }, categories: string[]) {
      const categoriesToFetch = [...categories].filter(
        (c) => !(getters.hasCategory(c) || getters.isFetching(c)),
      );
      commit('START_FETCHING', categoriesToFetch);
      if (categoriesToFetch.length) {
        ContactOptionAPI.getAll(categoriesToFetch)
          .then((responses: Record<string, ContactOptionInterface[]>) => {
            commit('SET_ERROR', null);
            commit('SET_CONTACT_OPTIONS', responses);
          }).catch((e) => {
            commit('SET_ERROR', e);
            commit('SET_CONTACT_OPTIONS', {});
          }).finally(() => {
            commit('STOP_FETCHING', categoriesToFetch);
          });
      }
    },
  },

  getters: {
    hasCategory: (state) => (category: string) => !!state.contactOptions[category],
    isFetching: (state) => (category: string) => state.fetching.includes(category),
    get: (state) => (categories: string[]) => {
      if (categories.indexOf('all') >= 0) {
        return state.contactOptions;
      }

      const contactOptions: Record<string, ContactOptionInterface[]> = {};

      categories.forEach((category: string) => {
        contactOptions[category] = state.contactOptions[category] || [];
      });

      return contactOptions;
    },
  },
};

export default contactOptionsModule;
