import axios from 'axios';
import PageInterface from '@/interfaces/PageInterface';
import { env } from '@/helpers';

class PageAPI {
  static getFront(preview = false): Promise<PageInterface> {
    return new Promise((resolve, reject) => {
      axios.get<PageInterface>(`${env('API_BASE_PATH')}frontpage`, {
        params: {
          preview,
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        const pageResponse = e?.response?.data;

        if (pageResponse) {
          resolve(pageResponse);
        } else {
          reject(e);
        }
      });
    });
  }

  static getById(id: number, preview = false): Promise<PageInterface> {
    return new Promise((resolve, reject) => {
      axios.get<PageInterface>(`${env('API_BASE_PATH')}get-page`, {
        params: {
          p: id,
          preview,
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        const pageResponse = e?.response?.data;

        if (pageResponse) {
          resolve(pageResponse);
        } else {
          reject(e);
        }
      });
    });
  }

  static get(slug: string, preview = false): Promise<PageInterface> {
    return new Promise((resolve, reject) => {
      axios.get<PageInterface>(`${env('API_BASE_PATH')}pages-nested`, {
        params: {
          slug,
          preview,
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        const pageResponse = e?.response?.data;

        if (pageResponse) {
          resolve(pageResponse);
        } else {
          reject(e);
        }
      });
    });
  }

  static getSystemPage(slug: string): Promise<PageInterface> {
    return new Promise((resolve, reject) => {
      axios.get<PageInterface>(`${env('API_BASE_PATH')}system-page`, {
        params: {
          slug,
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        const pageResponse = e?.response?.data;

        if (pageResponse) {
          resolve(pageResponse);
        } else {
          reject(e);
        }
      });
    });
  }

  static getCity(slug: string, preview = false): Promise<PageInterface> {
    return new Promise((resolve, reject) => {
      axios.get<PageInterface>(`${env('API_BASE_PATH')}city-page`, {
        params: {
          slug,
          preview,
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        const pageResponse = e?.response?.data;

        if (pageResponse) {
          resolve(pageResponse);
        } else {
          reject(e);
        }
      });
    });
  }
}

export default PageAPI;
