import { Module } from 'vuex';
import CustomerFeedbackInterface from '@/interfaces/CustomerFeedbackInterface';
import { CustomerFeedbackAPI } from '@/api';

const customerFeedbacksModule: Module<any, any> = {
  namespaced: true,

  state: {
    customerFeedbacks: [] as CustomerFeedbackInterface[],
    fetching: [],
    error: null,
  },

  mutations: {
    SET_CUSTOMER_FEEDBACKS(state, customerFeedbacks: CustomerFeedbackInterface[]) {
      customerFeedbacks.forEach((customerFeedback) => {
        const customerFeedbackAlreadyExists = state.customerFeedbacks.filter((f: CustomerFeedbackInterface) => f.id === customerFeedback.id).length;

        if (!customerFeedbackAlreadyExists) {
          state.customerFeedbacks.push(customerFeedback);
        }
      });
    },
    START_FETCHING(state, categories: string[]) {
      state.fetching = [...state.fetching, ...categories];
    },
    STOP_FETCHING(state, categories: string[]) {
      state.fetching = [...state.fetching].filter((c) => !categories.includes(c));
    },
    SET_ERROR(state, error: unknown) {
      state.error = error;
    },
  },

  actions: {
    get({ getters, commit }, categories: string[]) {
      const categoriesToFetch = [...categories].filter(
        (c) => !(getters.hasCategory(c) || getters.isFetching(c)),
      );
      commit('START_FETCHING', categoriesToFetch);
      if (categoriesToFetch.length) {
        CustomerFeedbackAPI.get(categoriesToFetch)
          .then((responses: Record<string, CustomerFeedbackInterface[]>) => {
            commit('SET_ERROR', null);
            commit('SET_CUSTOMER_FEEDBACKS', responses);
          }).catch((e) => {
            commit('SET_ERROR', e);
            commit('SET_CUSTOMER_FEEDBACKS', {});
          }).finally(() => {
            commit('STOP_FETCHING', categoriesToFetch);
          });
      }
    },
  },

  getters: {
    hasCategory: (state) => (category: string) => state.customerFeedbacks.filter((f: CustomerFeedbackInterface) => f.categories.filter((c: { slug: string }) => c.slug === category).length).length,
    isFetching: (state) => (category: string) => state.fetching.indexOf(category) >= 0,
    get: (state) => (categories: string[]) => {
      const customerFeedbacks: CustomerFeedbackInterface[] = [];
      const allCategories = categories.indexOf('all') >= 0;

      if (allCategories) {
        return [...state.customerFeedbacks];
      }

      state.customerFeedbacks.forEach((customerFeedback: CustomerFeedbackInterface) => {
        const customerFeedbackAlreadyExists = customerFeedbacks.filter((f: CustomerFeedbackInterface) => f.id === customerFeedback.id).length > 0;

        if (!customerFeedbackAlreadyExists) {
          const hasCategory = customerFeedback.categories.filter((c: { slug: string }) => categories.indexOf(c.slug) >= 0).length > 0;

          if (hasCategory) {
            customerFeedbacks.push(customerFeedback);
          }
        }
      });

      return customerFeedbacks;
    },
  },
};

export default customerFeedbacksModule;
