import axios from 'axios';
import FaqInterface from '@/interfaces/FaqInterface';
import FaqCategoryInterface from '@/interfaces/FaqCategoryInterface';
import { env } from '@/helpers';

class FaqAPI {
  static get(categories: string[] = ['all']): Promise<{
    items: Record<string, FaqInterface[]>,
    categories: Record<string, FaqCategoryInterface[]>
  }> {
    return new Promise((resolve, reject) => {
      axios.get<{
        items: Record<string, FaqInterface[]>,
        categories: Record<string, FaqCategoryInterface[]>
      }>(`${env('API_BASE_PATH')}faqs`, {
        params: {
          categories: categories.join(','),
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e);
      });
    });
  }
}

export default FaqAPI;
