import axios from 'axios';
import AjaxResponseInterface from '@/interfaces/AjaxResponseInterface';
import CityInterface from '@/interfaces/CityInterface';
import StreetInterface from '@/interfaces/StreetInterface';
import SupplierInterface from '@/interfaces/SupplierInterface';

class EnetAPI {
  static getCities(postcode: string | number, keyed = false): Promise<CityInterface[] | { cities: CityInterface[] }> {
    return new Promise((resolve, reject) => {
      axios.get<CityInterface[]>(`${window.ioappConfig.ajaxUrl}`, {
        params: {
          action: 'enet',
          endpoint: 'cities',
          postcode,
        },
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as Record<string, unknown>;
        resolve(keyed ? { cities: ajaxResponseData.cities as CityInterface[] } : ajaxResponseData.cities as CityInterface[]);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getStreets(postcode: string | number, keyed = false): Promise<StreetInterface[] | { streets: StreetInterface[] }> {
    return new Promise((resolve, reject) => {
      axios.get<StreetInterface[]>(`${window.ioappConfig.ajaxUrl}`, {
        params: {
          action: 'enet',
          endpoint: 'streets',
          postcode,
        },
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as Record<string, unknown>;
        resolve(keyed ? { streets: ajaxResponseData.streets as StreetInterface[] } : ajaxResponseData.streets as StreetInterface[]);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getCitiesAndStreets(postcode: string | number)
    : Promise<{
    postcode: string | number,
    cities: CityInterface[],
    streets: StreetInterface[],
  }> {
    return new Promise((resolve, reject) => {
      const requests = [
        this.getCities(postcode, true),
        this.getStreets(postcode, true),
      ];

      Promise.all(requests).then((responses) => {
        let citiesAndStreets = {};

        responses.forEach((response) => {
          citiesAndStreets = {
            ...citiesAndStreets,
            ...response,
          };
        });

        resolve({
          postcode,
          ...citiesAndStreets,
        } as {
          postcode: string | number,
          cities: CityInterface[],
          streets: StreetInterface[],
        });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getSuppliers(branch = 'all')
    : Promise<{
    branch: string,
    supplier: SupplierInterface[],
  }> {
    return new Promise((resolve, reject) => {
      axios.get<SupplierInterface[]>(`${window.ioappConfig.ajaxUrl}`, {
        params: {
          action: 'enet',
          endpoint: 'getSuppliers',
          branch,
        },
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as Record<string, unknown>;
        resolve({
          branch,
          supplier: ajaxResponseData.supplier as SupplierInterface[],
        });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getAllSuppliers()
    : Promise<{
    power: SupplierInterface[],
    gas: SupplierInterface[],
  }> {
    return new Promise((resolve, reject) => {
      axios.get<{
        power: SupplierInterface[],
        gas: SupplierInterface[],
      }>(`${window.ioappConfig.ajaxUrl}`, {
        params: {
          action: 'enet',
          endpoint: 'getSuppliers',
          branch: 'all',
        },
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const supplier = ajaxResponse.data as {
          power: SupplierInterface[],
          gas: SupplierInterface[],
        };
        resolve(supplier);
      }).catch((e) => {
        reject(e);
      });
    });
  }
}

export default EnetAPI;
