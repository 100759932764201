import './webpack.tweaks.standalone';
import { createApp, defineAsyncComponent } from 'vue';
import '@/interfaces/WindowInterface';
import 'vue-next-select/dist/index.css';
import '@/scss/app.standalone.scss';
import { useStore } from 'vuex';
import store from './store';

const BaseSpacer = defineAsyncComponent(() => import(/* webpackChunkName: 'chunks/base-spacer' */ '@/components/BaseSpacer.vue'));
const BaseCalculator = defineAsyncComponent(() => import(/* webpackChunkName: 'chunks/base-calculator' */ '@/components/BaseCalculator.vue'));
const BaseCalculatorV2 = defineAsyncComponent(() => import(/* webpackChunkName: 'chunks/base-calculator-v2' */ '@/components/BaseCalculatorV2.vue'));
const IconCart = defineAsyncComponent(() => import(/* webpackChunkName: 'chunks/base-calculator-v2' */ '@/components/IconCart.vue'));
const CartCount = defineAsyncComponent(() => import(/* webpackChunkName: 'chunks/base-cart-count' */ '@/components/standalone/CartCount.vue'));
const CartAdditionalCategory = defineAsyncComponent(() => import(/* webpackChunkName: 'chunks/cart-additional-category' */ '@/components/cart/CartAdditionalCategory.vue'));
const NewsletterForm = defineAsyncComponent(() => import(/* webpackChunkName: 'chunks/forms' */ '@/components/forms/NewsletterForm.vue'));

const standaloneCalculatorContainers = document.querySelectorAll('.base-calculator-standalone');

standaloneCalculatorContainers.forEach((appContainer) => {
  const appDiv = appContainer;
  appDiv.id = `io-shop-calculator-${Math.random().toString(36).substr(2, 9)}`;

  const calculatorApp = createApp({
    beforeMount() {
      const vuexStore = useStore();

      vuexStore.dispatch('cart/fetch');
      vuexStore.dispatch('categories/fetch');
      vuexStore.dispatch('branches/fetch');
    },
  });
  calculatorApp.component('BaseCalculator', BaseCalculator);
  calculatorApp.component('BaseCalculatorV2', BaseCalculatorV2);
  calculatorApp.component('BaseSpacer', BaseSpacer);
  calculatorApp.component('IconCart', IconCart);
  calculatorApp.component('CartCount', CartCount);
  calculatorApp.component('CartAdditionalCategory', CartAdditionalCategory);
  calculatorApp.component('NewsletterForm', NewsletterForm);
  calculatorApp.use(store);
  calculatorApp.mount(`#${appDiv.id}`);
});
