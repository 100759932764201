import ConfigInterface from '@/interfaces/ConfigInterface';

export function formatNumber(number: unknown, digits: number | null, digitsOptional = false): string {
  const floatNumber = parseFloat(`${number || 0}`);

  let numDigits = digits || 0;
  if (digitsOptional && Math.round(floatNumber) === floatNumber) {
    numDigits = 0;
  }

  return floatNumber
    .toLocaleString(navigator.language, {
      maximumFractionDigits: numDigits,
      minimumFractionDigits: numDigits,
    });
}

export function getNumberPrecision(a: number): number {
  let digits = 1;

  while (Math.round(a * digits) / digits !== a) {
    digits *= 10;
  }

  return Math.ceil(Math.log(digits) / Math.LN10);
}

export function anonymizeIban(iban: string): string {
  return `${iban.substring(0, 2)}**${iban.substring(4, 7)}${'*'.repeat(11)}${iban.slice(-4)}`;
}

export function ucFirst(inputString: string): string {
  return inputString.charAt(0)
    .toUpperCase() + inputString.slice(1);
}

export function objectToUtf8QueryString(params: Record<string, any>): string {
  const queryParams = [] as Array<string>;

  Object.keys(params).forEach((key) => {
    if ([null, ''].indexOf(params[key]) < 0) {
      queryParams.push(`${key}=${params[key]}`);
    }
  });

  return `${queryParams.length ? '?' : ''}${queryParams.join('&')}`;
}

export function getAppConfig(): ConfigInterface {
  return window.ioappConfig || {};
}

export function env(key: string, defaultValue: any = null): unknown {
  const config = getAppConfig();
  const environmentVariables = config.env || {};

  return environmentVariables[key] || defaultValue;
}

export function setCookie(name: string, value: string, exdays = 28): void {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  const domain = document.domain.match(/[^.]*\.[^.]*$/)?.[0];

  document.cookie = `${name}=${value};${expires};path=/;${domain ? `domain=.${domain};` : ''}SameSite=Lax;Secure`;
}

export function getCookie(name: string): string {
  const $name = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf($name) === 0) {
      return c.substring($name.length, c.length);
    }
  }
  return '';
}

const formatFunctions = {
  formatNumber,
  anonymizeIban,
  ucFirst,
  getAppConfig,
  env,
  setCookie,
  getCookie,
};

export default formatFunctions;
