import axios from 'axios';
import ContactOptionInterface from '@/interfaces/ContactOptionInterface';
import { env } from '@/helpers';

class ContactOptionAPI {
  static getAll(categories: string[] = ['all']): Promise<Record<string, ContactOptionInterface[]>> {
    return new Promise((resolve, reject) => {
      axios.get<Record<string, ContactOptionInterface[]>>(`${env('API_BASE_PATH')}contact-options`, {
        params: {
          categories: categories.join(','),
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e);
      });
    });
  }
}

export default ContactOptionAPI;
