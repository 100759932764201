import { Module } from 'vuex';

const sidebarModule: Module<any, any> = {
  namespaced: true,

  state: {
    open: false,
  },

  mutations: {
    TOGGLE_SIDEBAR(state) {
      state.open = !state.open;
    },
    OPEN_SIDEBAR(state) {
      state.open = true;
    },
    CLOSE_SIDEBAR(state) {
      state.open = false;
    },
  },

  actions: {
    toggle({ commit }) {
      commit('TOGGLE_SIDEBAR');
    },
    open({ commit }) {
      commit('OPEN_SIDEBAR');
    },
    close({ commit }) {
      commit('CLOSE_SIDEBAR');
    },
  },

  getters: {
    open: (state) => state.open,
  },
};

export default sidebarModule;
