import axios from 'axios';
import PowerLabelingInterface from '@/interfaces/PowerLabelingInterface';
import { env } from '@/helpers';

class PowerLabelingAPI {
  static get(): Promise<PowerLabelingInterface[]> {
    return new Promise((resolve, reject) => {
      axios.get<PowerLabelingInterface[]>(`${env('API_BASE_PATH')}power-labelings`).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e);
      });
    });
  }
}

export default PowerLabelingAPI;
