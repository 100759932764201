import axios from 'axios';
import AjaxResponseInterface from '@/interfaces/AjaxResponseInterface';
import SalutationInterface from '@/interfaces/SalutationInterface';
import BillingTypeInterface from '@/interfaces/BillingTypeInterface';
import DataPreparedInterface from '@/interfaces/order/DataPreparedInterface';

class IoOrderAPI {
  static getSalutations(): Promise<{
    private: SalutationInterface[],
    business: SalutationInterface[],
  }> {
    return new Promise((resolve, reject) => {
      axios.get<{
        private: SalutationInterface[],
        business: SalutationInterface[],
      }>(`${window.ioappConfig.restAPIUrl}io-order`, {
        params: {
          endpoint: 'getSalutations',
        },
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as Record<string, unknown>;

        resolve(ajaxResponseData.salutations as {
          private: SalutationInterface[],
          business: SalutationInterface[],
        });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getBillingTypes(): Promise<BillingTypeInterface[]> {
    return new Promise((resolve, reject) => {
      axios.get<BillingTypeInterface[]>(`${window.ioappConfig.restAPIUrl}io-order`, {
        params: {
          endpoint: 'getBillingTypes',
        },
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as Record<string, unknown>;

        resolve(ajaxResponseData.billingTypes as BillingTypeInterface[]);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getDataPrepared(): Promise<DataPreparedInterface> {
    return new Promise((resolve, reject) => {
      axios.get<DataPreparedInterface>(`${window.ioappConfig.ajaxUrl}`, {
        params: {
          action: 'io_order',
          endpoint: 'getDataPrepared',
        },
      }).then((response) => {
        const ajaxResponse = response.data as unknown as AjaxResponseInterface;
        const ajaxResponseData = ajaxResponse.data as Record<string, unknown>;

        resolve(ajaxResponseData.dataPrepared as DataPreparedInterface);
      }).catch((e) => {
        reject(e);
      });
    });
  }
}

export default IoOrderAPI;
