import BranchInterface from '@/interfaces/BranchInterface';
import { Module } from 'vuex';
import { IoProductAPI } from '@/api';

const branchesModule: Module<any, any> = {
  namespaced: true,

  state: {
    branches: [] as BranchInterface[],
    error: null,
  },

  mutations: {
    SET_BRANCHES(state, categories: BranchInterface[]) {
      state.branches = categories;
    },
    SET_ERROR(state, error: unknown) {
      state.error = error;
    },
  },

  actions: {
    fetch({ commit }) {
      IoProductAPI.getBranches()
        .then((categories) => {
          commit('SET_ERROR', null);
          commit('SET_BRANCHES', categories || []);
        })
        .catch((e: unknown) => {
          commit('SET_ERROR', e);
          commit('SET_BRANCHES', []);
        });
    },
  },

  getters: {
    all: (state): BranchInterface[] => (state.branches || []).filter((branch: BranchInterface) => !branch.isUrl),
    allWithUrls: (state): BranchInterface[] => (state.branches || []),
    forCalculatorV2: (state): BranchInterface[] => (state.branches || []),
  },
};

export default branchesModule;
