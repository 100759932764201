import axios from 'axios';
import MenuInterface from '@/interfaces/MenuInterface';
import { env } from '@/helpers';

class MenuAPI {
  static get(slug: number | string): Promise<MenuInterface> {
    return new Promise((resolve, reject) => {
      axios.get<MenuInterface>(`${env('API_BASE_PATH')}menu`, {
        params: {
          slug,
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e);
      });
    });
  }
}

export default MenuAPI;
