import ProductCategoryInterface from '@/interfaces/ProductCategoryInterface';
import { Module } from 'vuex';
import { IoProductAPI } from '@/api';

const categoriesModule: Module<any, any> = {
  namespaced: true,

  state: {
    categories: [] as ProductCategoryInterface[],
    error: null,
  },

  mutations: {
    SET_CATEGORIES(state, categories: ProductCategoryInterface[]) {
      state.categories = categories;
    },
    SET_ERROR(state, error: unknown) {
      state.error = error;
    },
  },

  actions: {
    fetch({ commit }) {
      IoProductAPI.getCategories()
        .then((categories) => {
          commit('SET_ERROR', null);
          commit('SET_CATEGORIES', categories || []);
        }).catch((e: unknown) => {
          commit('SET_ERROR', e);
          commit('SET_CATEGORIES', []);
        });
    },
  },

  getters: {
    commodityAll: (state): ProductCategoryInterface[] => state.categories.filter((category: ProductCategoryInterface) => category.isCommodity),
    commodityActive: (state, getters): ProductCategoryInterface[] => getters.commodityAll.filter((category: ProductCategoryInterface) => category.isActive),
    commodityInactive: (state, getters): ProductCategoryInterface[] => getters.commodityAll.filter((category: ProductCategoryInterface) => !category.isActive),
    catalogAll: (state): ProductCategoryInterface[] => state.categories.filter((category: ProductCategoryInterface) => !category.isCommodity),
    catalogActive: (state, getters): ProductCategoryInterface[] => getters.catalogAll.filter((category: ProductCategoryInterface) => category.isActive),
    catalogInactive: (state, getters): ProductCategoryInterface[] => getters.catalogAll.filter((category: ProductCategoryInterface) => !category.isActive),
  },
};

export default categoriesModule;
