import BranchInterface from '@/interfaces/BranchInterface';
import ProductCategoryInterface from '@/interfaces/ProductCategoryInterface';
import ProductContractDetailsInterface from '@/interfaces/ProductContractDetailsInterface';
import ProductMediaInterface from '@/interfaces/ProductMediaInterface';
import ProductPriceInterface from '@/interfaces/ProductPriceInterface';
import axios from 'axios';
import { Product } from '@/classes';
import ProductInterface from '@/interfaces/ProductInterface';

class IoProductAPI {
  static getAll(params: Record<string, unknown>): Promise<ProductInterface[]> {
    return new Promise((resolve, reject) => {
      axios.get(`${window.ioappConfig.restAPIUrl}io-product`, {
        params: {
          endpoint: 'getAll',
          ...params,
        },
      }).then((response) => {
        resolve(response.data?.data.products);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getPrice(productId: number, params: Record<string, unknown>): Promise<{
    price: ProductPriceInterface
    media: ProductMediaInterface
  }> {
    return new Promise((resolve, reject) => {
      axios.get(`${window.ioappConfig.restAPIUrl}io-product`, {
        params: {
          endpoint: 'getPrice',
          ...params,
          productId,
        },
      }).then((response) => {
        resolve({
          price: response.data?.data.price,
          media: response.data?.data.media,
        });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getAllWithPrices(params: Record<string, unknown>): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      this.getAll({
        branch: params.branch,
        business: params.business,
      }).then((products) => {
        const priceRequests = products.map((product) => this.getPrice(product.id, params));
        Promise.allSettled(priceRequests).then((responses) => {
          const productsWithPrices = responses.map((result) => {
            if (result.status !== 'fulfilled') return undefined;

            const price = result?.value?.price as ProductPriceInterface | undefined;
            const media = result?.value?.media as ProductMediaInterface | undefined;

            if (!price) return undefined;
            if (!media) return undefined;

            const product = products.find((p) => p.id === price.productId);

            if (!product) return undefined;

            const contractDetails = { ...price?.contractDetails } as ProductContractDetailsInterface | undefined;

            if (!contractDetails) return undefined;

            delete price.contractDetails;
            delete price.productId;

            return new Product({
              ...product,
              price: result?.value?.price || undefined,
              details: {
                ...product.details,
                contractDetails,
                media,
              },
            });
          }).filter((product) => !!product) as Product[];

          if (productsWithPrices.length) {
            resolve(productsWithPrices);
          } else {
            reject(new Error('No prices found'));
          }
        }).catch((e) => {
          reject(e);
        });
      });
    });
  }

  static getPriceExists(params: Record<string, unknown>): Promise<{
    postcode: boolean,
    street: boolean,
    networkNumber: boolean,
  }> {
    return new Promise((resolve, reject) => {
      axios.get(`${window.ioappConfig.restAPIUrl}io-product`, {
        params: {
          endpoint: 'getPriceExists',
          ...params,
        },
      }).then((response) => {
        resolve(response.data?.data);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getCategories(tree = false): Promise<ProductCategoryInterface[]> {
    return new Promise((resolve, reject) => {
      axios.get(`${window.ioappConfig.restAPIUrl}io-product`, {
        params: {
          endpoint: 'getCategories',
          tree,
        },
      }).then((response) => {
        resolve(response.data?.data?.categories || []);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static getBranches(): Promise<BranchInterface[]> {
    return new Promise((resolve, reject) => {
      axios.get(`${window.ioappConfig.restAPIUrl}io-product`, {
        params: {
          endpoint: 'getBranches',
        },
      }).then((response) => {
        resolve(response.data?.data?.branches || []);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  static validateCoupon(code: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios.get(`${window.ioappConfig.restAPIUrl}io-product`, {
        params: {
          endpoint: 'validateCoupon',
          code,
        },
      }).then((response) => {
        resolve(response?.data?.data?.valid || false);
      }).catch((e) => {
        reject(e);
      });
    });
  }
}

export default IoProductAPI;
