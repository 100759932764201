import { Module } from 'vuex';
import CartCommodityInterface from '@/interfaces/CartCommodityInterface';
import { CartAPI } from '@/api';
import { Product } from '@/classes';
import CampaignInterface from '@/interfaces/CampaignInterface';
import { setCookie } from '@/helpers';

const cartModule: Module<any, any> = {
  namespaced: true,

  state: {
    loading: false,
    commodity: {} as Record<string, CartCommodityInterface>,
    nonCommodity: {} as CartCommodityInterface,
    campaigns: [] as CampaignInterface[],
    error: null,
    showWidget: false,
  },

  mutations: {
    SET_LOADING(state, isLoading: boolean) {
      state.loading = isLoading;
    },
    SET_COMMODITY(state, cartCommodity: CartCommodityInterface) {
      state.commodity = cartCommodity;
    },
    SET_NON_COMMODITY(state, cartNonCommodity: CartCommodityInterface) {
      state.nonCommodity = cartNonCommodity;
    },
    SET_CAMPAIGNS(state, cartCampaigns: CampaignInterface[]) {
      state.campaigns = cartCampaigns;
    },
    SET_ERROR(state, error: unknown) {
      state.error = error;
    },
    TOGGLE_SHOW_WIDGET(state) {
      state.showWidget = !state.showWidget;
    },
  },

  actions: {
    fetch({ commit }) {
      commit('SET_LOADING', true);

      CartAPI.get()
        .then((response) => {
          commit('SET_ERROR', null);
          commit('SET_COMMODITY', response?.commodity || []);
          commit('SET_NON_COMMODITY', response?.nonCommodity || []);
          commit('SET_CAMPAIGNS', response?.campaigns || []);
        }).catch((e) => {
          commit('SET_ERROR', e);
          commit('SET_NON_COMMODITY', {});
          commit('SET_COMMODITY', {});
          commit('SET_CAMPAIGNS', []);
        }).finally(() => {
          commit('SET_LOADING', false);
        });
    },
    add({ commit }, data: {
      product: Product,
      cartItemKey: string | null,
      params: Record<string, any>,
      entry: boolean,
    }): Promise<unknown> {
      commit('SET_LOADING', true);

      return new Promise((resolve, reject) => {
        CartAPI.add(data.product, data.params, data.cartItemKey).then((response) => {
          if (response.cartKey) {
            setCookie('io_shop_cart', response.cartKey);
          }

          commit('SET_ERROR', null);
          commit('SET_COMMODITY', response?.cartData?.commodity || []);
          commit('SET_NON_COMMODITY', response?.cartData?.nonCommodity || []);
          commit('SET_CAMPAIGNS', response?.cartData?.campaigns || []);

          resolve(response);
        }).catch((e) => {
          commit('SET_ERROR', e);
          reject(e);
        }).finally(() => {
          commit('SET_LOADING', false);
        });
      });
    },
    remove({ commit }, payload: { key: string, product: Product }): Promise<unknown> {
      const {
        product,
        key,
      } = payload;

      commit('SET_LOADING', true);

      return new Promise((resolve, reject) => {
        CartAPI.remove(key, product).then((response) => {
          if (response.cartKey) {
            setCookie('io_shop_cart', response.cartKey);
          }

          commit('SET_ERROR', null);
          commit('SET_COMMODITY', response?.cartData?.commodity || []);
          commit('SET_NON_COMMODITY', response?.cartData?.nonCommodity || []);
          commit('SET_CAMPAIGNS', response?.cartData?.campaigns || []);

          resolve(response);
        }).catch((e) => {
          commit('SET_ERROR', e);
          reject(e);
        }).finally(() => {
          commit('SET_LOADING', false);
        });
      });
    },
  },

  getters: {
    status: (state) => (type = 'commodity') => {
      const status = state.status[type];

      return typeof status === 'undefined' ? 0 : status;
    },
    isLoading: (state) => state.loading,
    showWidget: (state) => state.showWidget,
    commodity: (state) => state.commodity,
    commodityByBranches: (state) => {
      const commodityByBranches = {} as Record<string, CartCommodityInterface[]>;

      Object.values(state.commodity).forEach((cartItem) => {
        const product = (cartItem as CartCommodityInterface).product as Product;
        const { branch } = product;

        if (commodityByBranches[branch] === undefined) {
          commodityByBranches[branch] = [];
        }

        commodityByBranches[branch].push(cartItem as CartCommodityInterface);
      });

      return commodityByBranches;
    },
    commodityBranches: (state, getters) => Object.keys(getters.commodityByBranches),
    isCommodityMultiple: (state) => Object.values(state.commodity).length >= 2,
    isCommodityMultipleBranches: (state, getters) => getters.commodityBranches.length >= 2,
    commodityFirst: (state) => (Object.values(state.commodity).length ? Object.values(state.commodity)[0] : null),
    commodityFirstProduct: (state) => (Object.values(state.commodity).length ? (Object.values(state.commodity)[0] as CartCommodityInterface).product : null),
    nonCommodity: (state) => state.nonCommodity,
    countCommodity: (state, getters) => Object.keys(getters.commodity).length,
    countNonCommodity: (state, getters) => Object.keys(getters.nonCommodity).length,
    countAll: (state, getters) => getters.countCommodity + getters.countNonCommodity,
    commodityPerMonth(state) {
      let total = 0;

      Object.values(state.commodity).forEach((item) => {
        const {
          product,
        } = item as { product: Product };

        total += product.getPriceValue('monthly');
      });

      return total;
    },
    commodityTeeOff(state) {
      let total = 0;

      Object.values(state.commodity).forEach((item) => {
        const {
          product,
        } = item as { product: Product };

        total += product.applyPriceFormats('monthly', product.getPriceValue('monthly'));
      });

      return total;
    },
    commodityPerYear(state) {
      let total = 0;

      Object.values(state.commodity).forEach((item) => {
        const {
          product,
        } = item as { product: Product };

        total += product.getPriceValue('yearly');
      });

      return total;
    },
  },
};

export default cartModule;
