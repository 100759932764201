import FaqCategoryInterface from '@/interfaces/FaqCategoryInterface';
import { Module } from 'vuex';
import FaqInterface from '@/interfaces/FaqInterface';
import { FaqAPI } from '@/api';

const faqsModule: Module<any, any> = {
  namespaced: true,

  state: {
    faqs: [] as FaqInterface[],
    categories: [] as FaqCategoryInterface[],
    fetching: [],
    error: null,
  },

  mutations: {
    SET_FAQS(state, faqs: FaqInterface[]) {
      faqs.forEach((faq) => {
        const faqAlreadyExists = state.faqs.filter((f: FaqInterface) => f.id === faq.id).length;

        if (!faqAlreadyExists) {
          state.faqs.push(faq);
        }
      });
    },
    SET_CATEGORIES(state, categories: FaqCategoryInterface[]) {
      categories.forEach((category) => {
        const categoryAlreadyExists = state.categories.filter((c: FaqCategoryInterface) => c.term_id === category.term_id).length;

        if (!categoryAlreadyExists) {
          state.categories.push(category);
        }
      });
    },
    START_FETCHING(state, categories: string[]) {
      state.fetching = [...state.fetching, ...categories];
    },
    STOP_FETCHING(state, categories: string[]) {
      state.fetching = [...state.fetching].filter((c) => !categories.includes(c));
    },
    SET_ERROR(state, error: unknown) {
      state.error = error;
    },
  },

  actions: {
    get({ getters, commit }, categs: string[]) {
      const categoriesToFetch = [...categs].filter(
        (c) => !(getters.hasCategory(c) || getters.isFetching(c)),
      );
      commit('START_FETCHING', categoriesToFetch);
      if (categoriesToFetch.length) {
        FaqAPI.get(categoriesToFetch)
          .then(({ items, categories }) => {
            commit('SET_ERROR', null);
            commit('SET_FAQS', items);
            commit('SET_CATEGORIES', categories);
          }).catch((e) => {
            commit('SET_ERROR', e);
          }).finally(() => {
            commit('STOP_FETCHING', categoriesToFetch);
          });
      }
    },
  },

  getters: {
    hasCategory: (state) => (category: string) => state.categories.filter((categ: FaqCategoryInterface) => categ.slug === category).length,
    isFetching: (state) => (category: string) => state.fetching.indexOf(category) >= 0,
    get: (state) => (categories: string[]) => {
      const faqs: FaqInterface[] = [];
      const allCategories = categories.indexOf('all') >= 0;

      if (allCategories) {
        return [...state.faqs];
      }

      state.faqs.forEach((faq: FaqInterface) => {
        const faqAlreadyExists = faqs.filter((f: FaqInterface) => f.id === faq.id).length > 0;

        if (!faqAlreadyExists) {
          const faqCategories = state.categories.filter((categ: FaqCategoryInterface) => faq.categories.includes(categ.term_id));
          const hasCategory = faqCategories.filter((categ: FaqCategoryInterface) => categories.includes(categ.slug)).length > 0;

          if (hasCategory) {
            faqs.push(faq);
          }
        }
      });

      return faqs;
    },
    categories: (state) => (slugs: string[]) => {
      const allCategories = slugs.indexOf('all') >= 0;

      if (allCategories) {
        return [...state.categories];
      }

      return [...state.categories.filter((category: FaqCategoryInterface) => slugs.includes(category.slug))];
    },
  },
};

export default faqsModule;
