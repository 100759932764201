import axios from 'axios';
import ProductPost from '@/interfaces/ProductPostInterface';
import { env } from '@/helpers';

class ProductAPI {
  static get(id: number): Promise<ProductPost | null> {
    return new Promise((resolve, reject) => {
      axios.get<ProductPost | null>(`${env('API_BASE_PATH')}products`, {
        params: {
          id,
        },
      }).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e);
      });
    });
  }
}

export default ProductAPI;
